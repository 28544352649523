@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1em !important;
  font-weight: 300 !important;
  /* background-color: #fafafa; */
  letter-spacing: 0.5px;
  /* scrollbar-gutter: stable !important; */
  overflow-x: scroll !important;
}

button {
  -webkit-app-region: no-drag !important;
}

::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0) !important;
  width: 10px !important;
  height: 10px !important;
  z-index:999999999999999;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.6) !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  border: 1px solid #0000004d !important;
  z-index:999999999999999;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(140, 140, 140, 0.6) !important;
  z-index:999999999999999;
}

.fc-timegrid-event {
  cursor: pointer;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: 0px 0px 0px 0px #fff !important;
}
.fc-theme-standard .fc-scrollgrid {
  border: 0px !important;
}
.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: rgba(208, 208, 208, 0.05) !important;
}
.MuiPaper-rounded {
  border-radius: 0 !important;
}

.ccEmails {
  display: flex;
  flex-direction: column;
}

.ccEmailsLabel {
  margin-right: 100px !important;
}

.bodyClass {
  width: 100%;
  height: 100vh;
}

.PrivateNotchedOutline-root-12 {
  min-height: 60px;
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.listItemSecondary .MuiTypography-colorTextSecondary {
  /* color: #be00008a; */
  color: #007a0099;
}

.listItemSecondaryStaff .MuiTypography-colorTextSecondary {
  /* color: #be00008a; */
  color: rgb(140, 140, 140);
  margin-top: 6px;
}

.newClientButton {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-weight: 400;
  font-size: 1rem;
  padding: 0.5rem 0.8rem;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  cursor: pointer;
}

.newClientButton:hover {
  background-color: #006fe5;
  border-color: #006fe5;
}

.DatePicker {
  margin: 0 !important;
}

#searchInput {
  text-align: center;
  margin: 0;
  margin-bottom: 15px;
  font-size: inherit;
  line-height: inherit;
  padding: 5px;
  border-radius: 0.25rem;
  border: 1px solid rgba(153, 153, 153, 0.3);
}

#clientList {
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

.tableFixHeader {
  max-height: 100%;
}

.tableFixHeader tr {
  height: 10px;
}

.tableFixHeader thead th {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.searchBorderBottom {
  border: 1px solid #999999 !important;
}

#clientList li {
  border: 1px solid #999999;
  border-bottom: 0px solid transparent;
}

#clientList li:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom: 1px solid #999999;
}

#clientList li:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

#clientList li button {
  color: #666666;
  background-color: transparent;
  font-weight: 430;
  font-size: 1.5rem;
  width: 380px;
  height: 60px;
  border: 0;
  cursor: pointer;
}

#clientList li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.notes {
  min-width: 300px !important;
}

.clockButton {
  all: unset;
  cursor: pointer;
  width: 90px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  border: solid rgba(0, 0, 0, 0.3) 1px;
}

.clockButton:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.complete {
  cursor: initial;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.complete:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.horiVertCent {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nameButton {
  cursor: pointer;
}

.nameButton:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.garmentTableModal {
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 1em;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

#email,
#password {
  width: 95%;
}

#rememberLabel {
  margin-left: 5px;
  font-size: 12px;
}

.width12 {
  width: 240px !important;
}

.garmentTableSml {
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 1em;
  width: 550px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 700px) {
  .garmentTableSml {
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.8em;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
}

.overlayStyles {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.modalStyles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  width: 80%;
  max-width: 800px;
  height: 50%;
  max-height: 600px;
  z-index: 1000;
  text-align: center;
}

.inner {
  display: flex;
  justify-content: center;
}

.specialReqContent {
  /* margin-top: -5px; */
  /* height: 80%; */
  width: 96%;
  /* width: auto; */
  position: absolute;
  padding: 10px;
  top: 60px;
  bottom: 5px;
  /* right: 5px !important; */
  /* left: 5px; */
  border: none;
  resize: none;
  outline: none;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.leftStickyTh {
  z-index: 11 !important;
  left: 0;
}

.leftSticky {
  color: #006fe5;
  /* z-index: 9; */
  position: sticky;
  left: -2px;
  background-color: #ededed;
  border-left: solid 1px lightgrey;
}

.clientsList {
  box-sizing: border-box;
  width: 100%;
  min-height: 30px;
  margin-right: -12px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  outline: none;
  font-size: 0.9rem;
  font-weight: 250;
}

.jobNumber {
  box-sizing: border-box;
  width: 100%;
  min-height: 30px;
  text-align: center;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  font-size: 0.9rem;
  font-weight: 250;
}

.fill {
  width: 100%;
  height: 100%;
  min-height: 60px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.videoScheduleListTable {
  border-collapse: collapse;
  font-size: 0.9rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.videoScheduleListTable th {
  z-index: 10;
  text-align: center;
  min-width: 60px;
  max-width: 250px;
}

.videoScheduleListTable thead {
  text-align: center;
  margin-bottom: 10px;
}

/* .videoScheduleListTable tr:hover {
    background-color: rgba(0, 0, 0, 0.06) !important;
  } */

.videoScheduleListTable td {
  text-align: center;
  max-width: 400px;
  min-width: 200px;
  min-height: 80px;
  font-size: 1em !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  z-index: 10;
}

/* .videoScheduleListTable td:hover {
    background-color: rgba(0, 0, 0, 0.001) !important;
  } */

.videoScheduleListTable thead th {
  /* background-color: rgba(80, 130, 230, 0.7); */
  background-color: #4963a6;

  color: #ffffff;
  text-align: center;
}

.videoScheduleListTable th {
  padding: 12px 15px;
  white-space: nowrap;
}

.videoScheduleListTable tbody tr {
  border-bottom: 1px solid #dddddd;
}

/* .videoScheduleListTable tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  } */

.videoScheduleListTable tbody tr:last-of-type {
  border-bottom: 2px solid rgba(0, 152, 122, 0.8);
}

.jobsListTable {
  border-collapse: collapse;
  font-size: 0.9rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.jobsListTable th {
  z-index: 10;
  /* position: sticky !important;
    top: 0 !important; */
  text-align: center;
  min-width: 60px;
  max-width: 250px;
}

.jobsListTable thead {
  text-align: center;
  margin-bottom: 10px;
  /* position: sticky !important;
    top: 0 !important; */
}

.jobsListTable tr:hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

.jobsListTable td {
  text-align: center;
  max-width: 250px;
  min-height: 80px;
  font-size: 1em !important;
  /* max-height:50px; */
  /* border-right: 1px solid lightgrey;
    border-left: 1px solid lightgrey; */
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  z-index: 10;
}

.jobsListTable td:hover {
  /* background-color: rgba(0, 0, 0, 0.08)!important; */
  background-color: rgba(0, 0, 0, 0.001) !important;
  /* background-color: #dddddd; */
}
.jobsListTable thead th {
  background-color: rgba(80, 130, 230, 0.7);
  /* background-color: #426b69; */
  color: #ffffff;
  text-align: center;
}

.addNewModelBtn {
  float: right;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.newJobItem {
  padding-bottom: 10px !important;
}

.garmentTable {
  table-layout: fixed;
  border-collapse: collapse;
  /* margin: 25px 0; */
  font-size: 0.8em;
  width: 1800px;
  /* max-width: 1000px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.garmentTable:is(th, td),
.garmentTableModal:is(th, tr) {
  max-width: 1000px;
  table-layout: fixed;
}

.styled-table {
  word-break: break-all;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.8em;
  min-width: 400px;
  max-width: 98%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr,
.garmentTableSml thead tr,
.garmentTable thead tr,
.garmentTableModal thead tr {
  background-color: rgba(0, 152, 122, 0.8);
  color: #ffffff;
  text-align: left;
}

.garmentTableModal th,
.garmentTableModal td {
  padding: 12px 15px;
  text-align: center;
}

.jobsListTable th,
.styled-table th,
.styled-table td,
.garmentTableSml th,
.garmentTableSml td,
.garmentTable th,
.garmentTable td {
  padding: 12px 15px;
  white-space: nowrap;
}

.jobsListTable tbody tr,
.styled-table tbody tr,
.garmentTableSml tbody tr,
.garmentTable tbody tr {
  border-bottom: 1px solid #dddddd;
}

.jobsListTable tbody tr:nth-of-type(odd),
.styled-table tbody tr:nth-of-type(odd),
.garmentTableSml tbody,
.garmentTable tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.jobsListTable tbody tr:last-of-type,
.styled-table tbody tr:last-of-type,
.garmentTableSml tbody,
.garmentTable tbody tr:last-of-type {
  border-bottom: 2px solid rgba(0, 152, 122, 0.8);
}

.ccEmail {
  display: block;
}

.editSVG {
  width: 30px;
  padding: 8px 15px !important;
  cursor: pointer;
}

.hidden {
  display: none !important;
}

#plusSVG {
  cursor: pointer;
  height: 45px;
}

.modalContentWide {
  /* display: flex;
    justify-content: center; */
  border-radius: 20px;
  background-color: #ededed;
  width: 470px;
  height: 650px;
  z-index: 3;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.tableScroll {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: 570px;
}

.tableScroll2 {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  min-height: 500px;
  max-height: 700px;
}

.modalOverlay {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  /* display: flex;
    justify-content: center; */
  border-radius: 20px;
  width: 400px;
  height: 500px;
  z-index: 3;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  overflow: scroll;
}

/* .modalContentWide h3 {
    text-align: center;
    margin-bottom: 20px;
  } */

.center {
  text-align: center;
}
/* 
  .editOutsourcerLabels {
    margin-top: 20px;
    margin-left: 30px;
  }
  
  .editOutsourcerLabels label {
    margin-top: 20px;
    display: inline-block;
  }
  
  .editOutsourcerLabels input {
    display: inline-block;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 20px;
    width: 200px;
  } */

.spaceBetween {
  display: flex;
  /* justify-content: space-between; */
}

.spaceEvenly {
  display: flex;
  justify-content: space-evenly;
}

.selector {
  height: 100%;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.card {
  margin: 20px;
  background-color: rgba(0, 0, 0, 0.04);
  width: 300px;
  height: 400px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 2.2em;
  color: #424242;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 1200px) {
  .card {
    width: 250px;
    height: 350px;
    font-size: 1.4em;
  }
}

@media only screen and (max-width: 900px) {
  .spaceEvenly {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-evenly;
  }

  .card {
    width: 300px;
    height: 400px;
    font-size: 1.4em;
  }
}

@media only screen and (max-width: 600px) {
  .spaceEvenly {
    align-content: space-evenly;
  }

  .card {
    margin-top: -100px;
    width: 150px;
    height: 250px;
    font-size: 1em;
  }
}

.modelContainer {
  padding-top: 30px;
  /* padding-bottom: 6px; */
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  flex-wrap: wrap;
}

.modelContainerContainer {
  display: flex;
  flex-direction: row;
}

.deleteModelBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.model {
  padding-right: 10px !important;
  padding-bottom: 20px !important;
}

.card:hover {
  background-color: rgba(0, 0, 0, 0.06);
  color: #525252;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.09);
}

a:visited,
a:link,
a,
a:active,
a:focus,
a:hover {
  text-decoration: none !important;
  /* color: #424242; */
}

.centerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerTd {
  text-align: center;
  vertical-align: middle;
}

.pointer {
  cursor: pointer;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: yellow;
}

.addNewRowButton {
  cursor: pointer;
  border: none;
  color: white;
  height: 25px;
  background-color: rgba(0, 152, 122, 0.5);
  font-size: 20px;
}

.addNewRowButton:hover {
  background-color: rgba(0, 152, 122, 0.6);
}

/* Customize the label (the container) */

.MuiAutocomplete-input {
  text-overflow: initial !important;
  min-width: 78px !important;
}

.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none !important;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: -7px;
  left: 72px;
  height: 25px;
  width: 25px;
  background-color: rgba(0, 0, 0, 0.03);
}

/* On mouse-over, add a grey background color */

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.avatarContainerStyleModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  width: 100px;
  height: 130px;
  background-color: #00000015;
}

.avatarContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  background-color: #00000015;
}

.avatarContainerStyle:hover {
  background-color: #00000025;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop .modalTest {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

iframe {
  display: none;
}
