body {
  overflow-y: hidden;
}

#board {
  display: flex;
  align-content: center;
  align-items: flex-start;
  /* height: 1px; */
  height: calc(100vh - 88px - 14px);
  margin-top: 2px;
  padding-top: 8px;
  padding-left: 8px;
  overflow-y: hidden;
}

.list {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  max-height: calc(90vh - 60px - 12px);
  white-space: normal;
  width: 100% !important;
  overflow: auto;
  margin-bottom: 10px;
}

.list-cards {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  min-height: 0;
  overflow-y: auto;
}

.list-card {
  border-radius: 3px;
  cursor: pointer;
  display: block;
  padding: 4px;
  position: relative;
  scroll-margin: 8px;
}

.list-virtual {
  display: flex;
  overflow: auto;
  flex-direction: column;
  position: relative;
  white-space: normal;
  width: 100% !important;
  height: calc(100% - 65px);
}

.list-header {
  flex: 0 0 auto;
  height: 40px;
  padding: 6px;
  position: relative;
}

.list-virtual > div > div {
  position: relative;
  white-space: normal;
  width: 100% !important;
  padding: 5px;
}

.list-virtual > div > div > *:last-child {
  padding-bottom: 20px;
}

.list-wrapper {
  box-sizing: border-box;
  display: inline-block;
  max-height: 100%;
  min-height: 100px;
  margin: 0 6px;
  vertical-align: top;
  white-space: nowrap;
  width: 272px;
  border-radius: 8px;
}

.list-wrapper-virtual {
  /* --list-background-color: var(--tr-background-list, #101010);
  background-color: var(--list-background-color); */
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  margin: 0 6px;
  vertical-align: top;
  white-space: nowrap;
  width: 272px;
  border-radius: 8px;
}

.height-preserving-container:empty {
  min-height: calc(var(--child-height));
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-outer {
  padding-top: 5px;
}

.item {
  /* background-color: #272727; */
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  /* z-index: 9999; */
}

.item:hover {
  background-color: #2d2d2d;
}

.is-dragging {
  /* background-color: red !important; */
  /* background-color: #1f1f1f; */
  rotate: 2deg;
}

.placeholder {
  position: absolute;
  background-color: white;
  border-radius: 3px;
  border: dashed 1px blue;
  background-color: white;
}

.addNewPostCard {
  margin: 10px;
  padding: 4px;
  border-radius: 6px;
  /* background: red; */
  font-size: 12px;
  font-weight: bold;
}

.MuiAvatar-root {
  border: 1px solid #4b4b4b !important;
}
