.stickytabs {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding-top: 12px;
  padding-bottom: 8px;
  /* background: red; */
  background: #ffffff;
  width: calc(100% - 50px);
  -webkit-app-region: drag !important;
}

.stickytabsShoot {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
  z-index: 9999;
  padding-top: 2px;
  padding-bottom: 8px;
  /* background: red; */
}

.stickyClientName {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding-top: 2px;
  padding-bottom: 8px;
  /* background: red; */
  /* background: #ffffff; */
}

.stickyFab {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  right: 0;
  z-index: 200;
  position: fixed;
  /* padding-top: 2px;
  padding-bottom: 8px; */
  /* background: red; */
  /* background: #ffffff; */
}

.stickyclose {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 200;
}
