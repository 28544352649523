.MuiPaper-rounded {
  border-radius: 10px !important;
}
.fc-timegrid-slots td {
  height: 32px !important;
}

.fc-daygrid-event,
.fc-timegrid-event,
.fc-event-title {
  overflow: hidden;
}

.fc-toolbar-title {
  font-size: 1.4em !important;
}

.fc-toolbar-title {
  display: inline;
  padding-top: 20px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.MuiPaper-root,
.MuiPaper-elevation,
.MuiPaper-elevation5,
.css-1tehbvn-MuiPaper-root {
  box-shadow: none !important;
}

.DateRangePicker {
  z-index: 1;
}

iframe {
  display: none;
}

#bgDarkenElement {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 999999;
  pointer-events: none;
}
/* 
* {
  outline: 1px solid #f00 !important;
} */
