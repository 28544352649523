body {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;
}

.ql-container {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;
}

.graf {
    font-size: 14px !important;
    margin: 0 !important;
    margin-bottom: -5px !important;
}

.graf+ol {
    font-size: 14px !important;
}

.graf+ul {
    font-size: 14px !important;
}

.aspectRatioPlaceholder {
    margin: 0 !important;
}

.imageCaption {
    display: none;
}

/* [data-tippy-root] {
    display: none;
} */



.ql-snow .ql-size .ql-picker-item[data-value="14px"]::before {
    content: "14px";
    font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="12px"]::before {
    content: "12";
    font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="16px"]::before {
    content: "16";
    font-size: 16px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="18px"]::before {
    content: "18";
    font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="20px"]::before {
    content: "20";
    font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="22px"]::before {
    content: "22";
    font-size: 22px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="24px"]::before {
    content: "24";
    font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="26px"]::before {
    content: "26";
    font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="28px"]::before {
    content: "28";
    font-size: 28px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="30px"]::before {
    content: "30";
    font-size: 30px !important;
}