.avx-traffic-light-container {
  display: flex;
  -webkit-app-region: no-drag;
}
.avx-traffic-light-container > * {
  margin-right: 6px;
}
.avx-traffic-light-close {
  position: relative;
  width: 11.5px;
  height: 11.5px;
  /* border: 1px solid rgba(0, 0, 0, 0.18); */
  border-radius: 100%;
  background: #ec6a5e;
}
.avx-traffic-light-close:active {
  background: #bf4740;
}
.avx-traffic-light-minus {
  position: relative;
  width: 11.5px;
  height: 11.5px;
  /* border: 1px solid rgba(0, 0, 0, 0.18); */
  border-radius: 100%;
  background: #f4bf4f;
}
.avx-traffic-light-minus:active {
  background: #bf9123;
}
.avx-traffic-light-max {
  position: relative;
  width: 11.5px;
  height: 11.5px;
  /* border: 1px solid rgba(0, 0, 0, 0.18); */
  border-radius: 100%;
  background: #61c554;
}
.avx-traffic-light-max:active {
  background: #1f9a31;
}
.avx-traffic-light-blur {
  background: #dddddd;
}
.avx-traffic-light-disable {
  background: #dddddd;
}
.avx-traffic-light-disable:active {
  background: #dddddd;
}
.base-icon {
  position: relative;
  width: 11.5px;
  height: 11.5px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 100%;
}
